import React, {useRef, useEffect, useLayoutEffect, useContext} from "react";
import {toast} from "react-toastify";
import {CartContext} from "../cart";
import {Product} from "../models/product";
import {motion, useSpring} from "framer-motion";
import {Radio } from "../components/radio";
import {useIsMobile, usePrevious} from "../utils";
import {client} from "../client";
import { SEO } from '../components/seo';

const Sour = ({ sour, setSour, worker }) => {
  const {isMobile, screenWidth} = useIsMobile();
  const role = 
    worker === "尋豆師" ? 'bird' : 
    worker === "烘豆師" ? 'bear' : 'dog';
  const SourOption = ({ id }) => id === sour ? (
    <div>
      <div style={{ width: 70, height: 40, textAlign: 'center' }}>
        <img 
          src={`/game/${role}_${sour || '1'}.svg`} 
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      <div style={{ height: 12 }} />
      <div style={{fontWeight: 'bold', textAlign: 'center'}}>
        {id}
      </div>
    </div>
  ):
  (
      <div>
          <div style={{ 
            width: 70, height: 52, textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Radio
              selected={false}
              onChange={() => {
                  setSour(id);
              }}
              value={id}
              style={{ margin: 0 }}
          />
          </div>
          <div style={{fontWeight: 'bold', textAlign: 'center'}}>
            {id}
          </div>
      </div>
    );
  return (
      <motion.div
      >
          <div style={{
              width: '100%',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '48px 0',
          }}>

              <div style={{
                  width: '68%',
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
              }}>
                  步驟 4/5
                  <div className="title2">
                      對於酸度的接受程度 ?
                  </div>
                  <div
                      style={{width: '100%', height: 20}}
                  ></div>
                  <hr
                      style={{
                        color: "black",
                              background: 'black',
                          height: 1,
                          width: '100%',
                      }}
                  />
                  <div
                      style={{width: '100%', height: 40}}
                  ></div>
                  <div style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'start',
                      overflowX: 'auto',
                      ...(isMobile ? {
                        width: '100vw',
                        justifyContent: 'space-between',
                        padding: '0px 48px',
                      } : {
                        justifyContent: 'space-around',
                        width: '100%',
                      }),
                  }}>
                      <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <SourOption id={1} />
                        <div className="body2" style={{marginTop: 8, textAlign: 'center'}}>
                            弱
                        </div>
                      </div>
                      <SourOption id={2} />
                      <SourOption id={3} />
                      <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <SourOption id={4} />
                        <div className="body2" style={{marginTop: 8, textAlign: 'center'}}>
                            強
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </motion.div>
  );
}

const Flavor = ({ setFlavor, flavor }) => {
    const {isMobile, screenWidth} = useIsMobile();
    return (
      <motion.div
          initial={{
              opacity: 1
          }}
          animate={{
              opacity: 1
          }}
          transition={{
              duration: 3
          }}
          style={{
              width: '100%',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '48px 0',
          }}>

          <div style={{
              width: '68%',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          }}>
            步驟 3/5
              <div className="title2">
                  喜歡的咖啡風味
              </div>
              <div
                  style={{width: '100%', height: 20}}
              ></div>
              <hr
                  style={{
                        color: "black",
                              background: 'black',
                      height: 1,
                      width: '100%',
                  }}
              />
              <div
                  style={{width: '100%', height: 40}}
              ></div>
              <div style={{
                  width: '100%',
                  flexDirection: isMobile ? 'column' : 'row',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
              }}>
                  <div>
                    <img src='/game/fruit.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                      <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                          水果
                      </div>
                      <Radio
                          selected={flavor}
                          onChange={() => {
                              setFlavor('水果');
                          }}
                          value={'水果'}
                      />
                  </div>
                  <div>
                    <img src='/game/cetus.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                      <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                          柑橘
                      </div>
                      <Radio
                          selected={flavor}
                          onChange={() => {
                              setFlavor('柑橘');
                          }}
                          value={'柑橘'}
                      />
                  </div>
                  <div>
                    <img src='/game/floral.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                      <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                          花香
                      </div>
                      <Radio
                          selected={flavor}
                          onChange={() => {
                              setFlavor('花香');
                          }}
                          value={'花香'}
                      />
                  </div>
                  <div>
                    <img src='/game/sweet.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                      <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                          甜味
                      </div>
                      <Radio
                          selected={flavor}
                          onChange={() => {
                              setFlavor('甜感');
                          }}
                          value={'甜感'}
                      />
                  </div>
                  <div>
                    <img src='/game/nut.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                      <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                          堅果可可
                      </div>
                      <Radio
                          selected={flavor}
                          onChange={() => {
                              setFlavor('堅果可可');
                          }}
                          value={'堅果可可'}
                      />
                  </div>
              </div>
          </div>
      </motion.div>
  );
}

const Tool = ({ tool, setTool }) => {
    const {isMobile, screenWidth} = useIsMobile();
    return (
        <motion.div
            initial={{
                opacity: 1
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                duration: 3
            }}
            style={{
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '48px 0',
        }}>

            <div style={{
                width: '68%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                步驟 2/5
                <div className="title2">
                    最常使用的咖啡沖煮器具
                </div>
                <div
                    style={{width: '100%', height: 20}}
                ></div>
                <hr
                    style={{
                          color: "black",
                                background: 'black',
                        height: 1,
                        width: '100%',
                    }}
                />
                <div
                    style={{width: '100%', height: 40}}
                ></div>
                <div style={{
                    width: '100%',
                    flexDirection: isMobile ? 'column' : 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}>
                    <div>
                      <img src='/game/french.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                        <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                            法式濾壓
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('濾壓壺');
                            }}
                            value={'濾壓壺'}
                        />
                    </div>
                    <div>
                      <img src='/game/espresso.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                        <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                            義式
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('義式');
                            }}
                            value={'義式'}
                        />
                    </div>
                    <div>
                      <img src='/game/pour.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                        <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                            手沖壺
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('手沖');
                            }}
                            value={'手沖'}
                        />
                    </div>
                    <div>
                      <img src='/game/aeropress.svg' style={{height: 150}}/>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                        <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                            愛樂壓
                        </div>
                        <Radio
                            selected={tool}
                            onChange={() => {
                                setTool('愛樂壓');
                            }}
                            value={'愛樂壓'}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

const Worker = ({ worker, setWorker }) => {
    const {isMobile, screenWidth} = useIsMobile();
    return (
      <motion.div>
          <div style={{
              width: '100%',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '48px 0',
          }}>

              <div style={{
                  width: '68%',
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
              }}>
                  步驟 1/5
                  <div className="title2">
                      想讓誰為你服務
                  </div>
                  <div
                      style={{width: '100%', height: 20}}
                  ></div>
                  <hr
                      style={{
                        color: "black",
                              background: 'black',
                          height: 1,
                          width: '100%',
                      }}
                  />
                  <div
                      style={{width: '100%', height: 40}}
                  ></div>
                  <div style={{
                      width: '100%',
                      flexDirection: isMobile ? 'column' : 'row',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                  }}>
                      <div>
                          <div style={{ width: 150, height: 150 }}>
                            <img src='/game/bird.svg' style={{width: 150}}/>
                          </div>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                          <div className="body2" style={{textAlign: 'center'}}>
                              鵜鶘鳥 : 尋豆師
                          </div>
                          <Radio
                              selected={worker}
                              onChange={() => {
                                  setWorker('尋豆師');
                              }}
                              value={'尋豆師'}
                          />
                      </div>
                      <div>
                          <div style={{ width: 150, height: 150 }}>
                            <img src='/game/bear.svg' style={{maxWidth: '100%', maxHeight: '100%'}}/>
                          </div>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                          <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                              熊熊 : 烘豆師
                          </div>
                          <Radio
                              selected={worker}
                              onChange={() => {
                                  setWorker('烘豆師');
                              }}
                              value={'烘豆師'}
                          />
                      </div>
                      <div>
                          <div style={{ width: 150, height: 150 }}>
                            <img src='/game/dog.svg' style={{ maxWidth: '100%', maxHeight: '100%'}}/>
                          </div>
                          <div
                              style={{width: '100%', height: 16}}
                          ></div>
                          <div className="body2" style={{fontWeight: 'bold', textAlign: 'center'}}>
                              狗狗 : 咖啡店長
                          </div>
                          <Radio
                              selected={worker}
                              onChange={() => {
                                  setWorker('咖啡店長');
                              }}
                              value={'咖啡店長'}
                          />
                      </div>
                  </div>
              </div>
          </div>
      </motion.div>
  );
}



const Game = () => {
    const toolRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const flavorRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const sourRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [worker, setWorker] = React.useState<string | null>(null);
    const [tool, setTool] = React.useState<string | null>(null);
    const [flavor, setFlavor] = React.useState<string | null>(null);
    const [sour, setSour] = React.useState<number | null>(null);
    const [products, setProducts] = React.useState<Product[]>([]);

    const prevWorker = usePrevious(worker);
    const prevTool= usePrevious(tool);
    const prevFlavor = usePrevious(flavor);

    const spring = useSpring(0, {damping: 300, stiffness: 1000});

    useLayoutEffect(() => {
        spring.onChange(latest => {
            window.scrollTo(0, latest);
        });
    }, [spring]);



    const {addItem} = useContext(CartContext);

        useEffect(() => {
            client.request(`
          query {
  products(first: 100, query:"product_type:咖啡豆") {
    edges {
      node {
        id
        tags
        handle
        title
        availableForSale
        variants(first: 10) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
              }
            }
          }
        }
        images(first: 10) {
          edges {
            node {
              url(transform: { 
                crop: CENTER
                maxWidth: 500,
                maxHeight: 500})
              id
              thumbnailUrl: url(transform: { 
                crop: CENTER
                maxWidth: 360,
                maxHeight: 360})
            }
          }
        }
      }
    }
}
          }
        `).then((data) => {
                setProducts(
                    data.products.edges.map((e) => Product.fromShopify(e))
                );
                // console.log(
                //     data.products.edges.map((e) => Product.fromShopify(e))
                // );
            });

        }, [])

    useEffect(() => {
        if (prevWorker === null && worker) {
      document.querySelector('html').scrollTo({
              top: toolRef.current.offsetTop,
              behavior: 'smooth'
          });
        }
    }, [worker]);

    useEffect(() => {
        if (prevTool === null && tool) {
          document.querySelector('html').scrollTo({
              top: flavorRef.current.offsetTop,
              behavior: 'smooth'
          });
        }
    }, [tool]);
    useEffect(() => {
        if (prevFlavor === null && flavor) {
          document.querySelector('html').scrollTo({
              top: sourRef.current.offsetTop,
              behavior: 'smooth'
          });
        }
    }, [flavor]);





      const variantsToShow =
          products
            .filter((e) => {
                if (!e.tags.includes(`角色-${worker}`)) return false;
                if (!e.tags.includes(`工具-${tool}`)) return false;
                if (!e.tags.includes(`風味-${flavor}`)) return false;
                if (!e.tags.includes(`酸度-${sour}`)) return false;
                return true;
            })


    return (
        <div>
            <div style={{
            }}>
                <Worker setWorker={setWorker} worker={worker} />
                <div ref={toolRef}>
                  <Tool key="tool" tool={tool} setTool={setTool} />
                </div>
                <div ref={flavorRef}>
                  <Flavor key="flavor" flavor={flavor} setFlavor={setFlavor} />
                </div>
                <div ref={sourRef}>
                  <Sour key="sour" sour={sour} setSour={setSour} worker={worker} />
                </div>
                        <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}>
 <div
                        style={{
                            width: '68%',
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        步驟 5/5
                        <div className="title2">
                            加入購物車
                        </div>
                        <div
                            style={{width: '100%', height: 20}}
                        ></div>
                        <hr
                            style={{
                          color: "black",
                                height: 1,
                                background: 'black',
                                width: '100%',
                            }}
                        />
                        <div style={{display: 'flex', width: '100%', flexWrap: 'wrap', margin: '32px 0px', justifyContent: 'center', gap: 8}}>
                            {
                                variantsToShow.length > 0 ?
                                    variantsToShow.map((e) => (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: 8,
                                            alignItems: 'center'
                                        }}>
                                            <img src={e.coverUrl}
                                                 style={{width: 200, height: 200,
                                                objectFit: 'cover',
                                            }}/>
                                            <div style={{height: 8}}/>
                                            <div style={{
                                              fontWeight: 'bold', 
                                              height: 32, fontSize: 14, width: 200,
                                              textAlign: 'center',
                                              }}>
                                                {e.name}
                                            </div>
                                            <div style={{ marginTop: 16}}>
                                                {e.variants[0].name} / ${e.variants[0].price}
                                            </div>
                                            <div 
                        className="my-btn"
                        style={{
                                                display: 'flex',
                                                padding: '8px 48px',
                                                justifyContent: 'center',
                                                border: '1px solid',
                                                borderRadius: 8,
                                                margin: '24px 0px',
                                                cursor: "pointer",
                                            }} onClick={() => {
                                                toast('成功加入購物車');
                                                addItem([{
                                                    variantId: e.variants[0].id,
                                                    quantity: 1,
                                                }]);
                                            }}
                                              data-quantity="1"
                                              data-item_id={e.variants[0].id}
                                              data-item_name={e.name + ' ' + e.variants[0].name}
                                              data-price={e.variants[0].price}
                                            >
                                                加入購物車
                                            </div>
                                        </div>
                                    )) : <div style={{ width: '100%', textAlign: 'center'}}>
                                        沒有符合的商品
                                    </div>
                            }
                        </div>
                        </div>               
                </div>

            </div>
        </div>
    );
};

export default Game;
export const Head = () => (
  <SEO
    title="尋找適合你的咖啡豆"
    description="你可以透過我們設計的小測驗，找到適合你的咖啡豆！"
    image="https://cdn.shopify.com/s/files/1/0645/0436/5310/files/about-pic1.jpg"
  />
)
